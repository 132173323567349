

export default {
    name: 'YfnBag',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: {
            type: Object,
            default: () => {
                return {}
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
        sku: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            clear: '',
            currency: {},             // 货币
            defaultPopup: {},         // 弹窗默认样式
            defaultOptions: {},       // 加购组件默认配置
            propsOptions: {},         // 原配置
            rsOption: {               // 总配置
                visible: false,
                quantity: 1
            },
            rsInfo: {},               // 商品信息
            rsSku: {},                // sku配置信息
            product: {                // 操作后商品信息
                rsImg: '',
                rsPrice: '',
                rsOrgPrice: '',
                discountRate: '',
                rsProductName: ''
            },
            preview: {                // 预览
                visible: false
            },
            btn: {                    // 按钮
                hasAdd: false,
                hasCustomize: false,
            },
            customize: {              // 定制
                hasRequire: false,
                info: {},
                params: {}
            },
            customizeCompo: {        // 定制组件
                visible: false,
                hasPopup: true,
                url: '',
                on: {}
            },
            addBag: {},               // 加购
            swiperCompo: {            // swiper配置
                visible: false,
                activeIndex: 0,
                options: {},
            },
            skuCompo: {},             // sku组件交互参数
            log: {                    // 加购日志
                trace: {}
            }
        }
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.setOptionsData()
            },
            deep: true,
            immediate: true
        },
        sku: {
            handler() {
                this.setSkuData()
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // set data
        setOptionsData() {
            this.propsOptions = JSON.parse(JSON.stringify(this.options || {}));
            this.propsOptions.hasRequest ?? (this.propsOptions.hasRequest = true)
            if(this.propsOptions.visible) {
                if(!this.propsOptions.hasRequest) {
                    this.rsInfo = JSON.parse(JSON.stringify(this.info))
                    this.setInfoOptions()
                    this.rsOption.visible = true
                    return
                }
                if(this.info.productCode == this.rsInfo.productCode) {
                    this.rsOption.visible = true
                } else {
                    this.skuCompo = {}
                    this.getDetailAjax()
                }
            } else {
                this.rsOption.visible = false
            }
        },
        setSkuData() {
            this.rsSku = JSON.parse(JSON.stringify(this.sku || {}))
        },
        // 获取商品详情
        getDetailAjax() {
            this.$api.product.getDetail({
                productCode: this.info.productCode,
                seoUrl: this.info.seoUrl
            }).then(response => {
                const res = response.result;
                if(!res) return
                this.rsInfo = res || {}
                // 定制跳转
                if(this.rsOption.hasCusJump && this.rsInfo.customizeType == 2) {
                    this.jumpProduct()
                    return
                }
                // 配置项处理
                this.setInfoOptions()
            })
        },
        setInfoOptions() {
            this.rsOption = {...this.defaultOptions,...this.rsOption, ...this.propsOptions}
            this.setProductInfo()
            this.setImgSwiper()
        },
        // 设置商品信息
        setProductInfo() {
            const select = this.skuCompo.select || {};
            const img = this.rsInfo.pics[0] || {};
            // this.product.rsImg = select.skuPic || img.url
            this.swiperCompo.list = select.skuPics || []
            this.product.rsProductName = select.productName || this.rsInfo.productName
            this.product.rsPrice = select.displayMarketPrice || this.rsInfo.displaySalePrice
            this.product.rsOrgPrice = select.displayCostPrice || this.rsInfo.displayOrgPrice
            this.preview.visible = !!select.skuPic
            this.product.discountRate = select.discountRate || this.rsInfo.discountRate
            this.product.discountRate && (this.product.discountRate = `-${this.product.discountRate}%`)
            this.setBtnStatus()
        },
        // 设置按钮
        setBtnStatus() {
            const customizeInfo = this.customize.info || {};
            const customizeType = customizeInfo.customizeType || this.rsInfo.customizeType;
            this.btn.hasAdd = customizeType != 2
            this.btn.hasCustomize = customizeType != 1
        },
        // 设置橱窗图信息
        setImgSwiper() {
            const _this = this;
            this.swiperCompo.name = 'YfnBag-swiper'
            this.swiperCompo.list = this.rsInfo.pics || []
            this.swiperCompo.visible = !!this.swiperCompo.list.length
            this.swiperCompo.options = {
                slidesPerView: 'auto',
                spaceBetween: 10,
                on: {
                    slideChange: function() {
                        _this.swiperCompo.activeIndex = this.activeIndex
                        _this.preview.visible = false
                    }
                }
            }
        },
        // 跳转
        jumpProduct() {
            if(!this.rsOption.hasShowDetail) return
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl
                }
            })
        },
        // 预览
        handlePreview(obj) {
            let pics = [];
            this.swiperCompo?.list?.forEach(p => {
                pics.push(p.url)
            })
            // if(this.preview.visible && this.product.rsImg) {
            //     pics.splice(obj.$index, 0, this.product.rsImg)
            // }
            this.$ImagePreview({
                images: pics,
                startPosition: obj.$index
            })
        },
        // emit
        emitSkuSelect(obj) {
            this.skuCompo = obj || {}
            this.skuCompo.select = obj.select ?? {}
            this.setProductInfo()
            this.setCustomizeStatus()
            this.setCustomizeConfig()
            this.$emit('emit-sku-select', obj)
        },
        emitSkuData(obj) {
            this.skuCompo.list = obj.mergeList || []
            this.skuCompo.sku = obj.sku || {}
            this.setBtnStatus()
            this.setCustomizeStatus()
            this.$emit('emit-sku-data', obj)
        },
        emitConfirm() {
            const select = this.skuCompo.select || {};
            if(select.hasAllSelect) {
                this.setAddParams()
                this.$emit('emit-confirm', {
                    info: this.rsInfo,
                    sku: this.skuCompo,
                    options: this.rsOption,
                    addParams: this.addBag
                })
            }
        },
        emitAddFailed() {
            this.setAddParams()
            this.$emit('emit-failed', {
                info: this.rsInfo,
                sku: this.skuCompo,
                options: this.rsOption,
                addParams: this.addBag
            })
        },
        emitAddSuccess() {
            this.setAddParams()
            this.$emit('emit-success', {
                info: this.rsInfo,
                sku: this.skuCompo,
                options: this.rsOption,
                addParams: this.addBag
            })
        },
        // 步进器
        changeStep() {
            this.$emit('emit-change-step', {
                info: this.rsInfo,
                sku: this.skuCompo,
                options: this.rsOption
            })
        },
        // 弹窗事件
        close() {
            this.options.visible = false
            this.$emit('emit-close')
        },
        // 定制
        getCustomizeAjax() { // 定制-获取定制化规则
            this.$api.product.getCustomize(this.customize.params).then(response => {
                // customizeType 1 普通规格 2 定制规格 3 可定制/可加购规格
                const res = response?.result || {};
                this.customize.info = res
                this.setCustomizeInfo()
            })
        },
        setCustomizeInfo() { // 定制-信息设置
            const info = this.customize.info;
            this.customize.hasRequire = info.customizeType == 2
            this.setBtnStatus()
        },
        setCustomizeReset() { // 定制-重置
            this.customize = {
                visible: false,
                info: {},
                params: {}
            }
        },
        setCustomizeStatus() { // 定制-定制状态
            this.setCustomizeReset()
            const select = this.skuCompo.select || {};
            this.customize.params.spuId = this.rsInfo.id
            this.customize.params.skuId = select.id
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                select.hasAllSelect && this.getCustomizeAjax()
            }, 50)
        },
        setCustomizeConfig() { // 定制-定制组件配置
            const select = this.skuCompo.select || {};
            const customizeInfo = this.customize.info || {};
            const customizeType = customizeInfo.customizeType || this.rsInfo.customizeType;
            if(!select.hasAllSelect || customizeType == 1) return
            let queryArr = [];
            let queryStr = '';
            let query = {
                spu: select.spuId,
                sku: select.id,
                productCode: this.rsInfo.productCode,
                isVipService: false
            };
            Object.keys(query).forEach(key => {
                queryArr.push(`${key}=${query[key]}`)
            })
            queryStr = queryArr.join('&')
            this.customizeCompo.url = `${location.origin}/custonIndex?${queryStr}`
            this.customizeCompo.on = {
                setCustomizeDone: this.setCustomizeDone
            }
        },
        setCustomizeDone() { // 定制-完成
            this.customizeCompo.visible = false
            this.addBag.hasAddSuccess = true
            this.setAddSuccess()
        },
        handleCustomize() { // 定制-btn
            const select = this.skuCompo.select || {};
            if(this.rsOption.hasImdAddBag && select.hasAllSelect) {
                this.customizeCompo.visible = true
            }
            this.setAddPreToast()
            this.emitConfirm()
        },
        // 加购操作
        setAddAjax() { // 加购接口
            this.$api.product.setAddBag({
                data: JSON.stringify(this.addBag.params)
            }).then(response => {
                const res = response;
                this.addBag.hasAddSuccess = false
                if(res?.code == 'success') {
                    this.addBag.hasAddSuccess = true
                } else {
                    this.rsOption.addSucText = res.code
                }
                this.setAddSuccess()
            })
        },
        handleConfirm() { // 加购按钮
            if(this.rsOption.hasImdAddBag) {
                this.setAddBag()
            }
            this.setAddPreToast()
            this.emitConfirm()
        },
        setAddBag() { // 加购
            this.setAddPreToast()
            this.setAddParams()
            this.addBag.params && this.setAddAjax()
        },
        setAddPreToast() { // 加购前提示
            this.addBag.addNoText = ''
            this.skuCompo.list.some(item => {
                if(!item.status) {
                    this.addBag.addNoText = item.name
                    return true
                }
            })
            if(!this.skuCompo.select.hasAllSelect) {
                this.emitAddFailed()
                this.rsOption.hasAddNoToast && this.$Toast(this.$translate(`Please Choose`) + ' ' + this.addBag.addNoText)
            }
        },
        setAddParams() { // 加购参数设置
            const select = this.skuCompo.select || {};
            this.addBag.params = ''
            this.addBag.skuId = ''
            this.addBag.spuId = ''
            this.addBag.quantity = 1
            if(select.hasAllSelect) {
                this.addBag.skuId = select.id
                this.addBag.spuId = select.spuId||this.rsInfo.id
                this.addBag.quantity = this.rsOption.quantity
                this.addBag.params = [{
                    skuId: select.id,
                    spuId: select.spuId||this.rsInfo.id,
                    quantity: this.rsOption.quantity
                }]
            }
        },
        setAddSuccess() { // 加购后操作
            if(this.rsOption.hasAddSucToast) {
                this.$Toast(this.$translate(this.rsOption.addSucText))
            }
            if(this.addBag.hasAddSuccess) {
                this.updateBagQuantity()
                this.setAddLog()
                this.emitAddSuccess()
                this.rsOption.visible = false
            }else {
                this.rsOption.addSucText = this.options.addSucText || this.defaultOptions.addSucText
                this.emitAddFailed()
            }
            // 加购埋点
            this.setBuried()
        },
        updateBagQuantity() { // 更新加购数量
            this.$updateBagQuantity()
        },
        setAddLog() { // 加购日志
            let pathname = this.$route.name;
            let addPage = 1, spuItems = [];
            // 加购页面
            switch(pathname) {
                case 'Product':
                    addPage = 2
                    break;
                case 'OrderDetail':
                    addPage = 3
                    break;
                case 'PaySuccess':
                    addPage = 4
                    break;
                case 'PostDetail':
                    addPage =5
                    break;
                case 'CustomerDetail':
                    addPage = 6
                    break;
                case 'Wish':
                    addPage = 8
                    break;
                case 'Mine':
                    addPage = 9
                    break;
                case 'Category':
                    addPage = 10
                    break;
                case 'Activity':
                    addPage = 11
                    break;
                case 'ShippingBag':
                    addPage = 12
                    break;
                case 'Order':
                    addPage = 13
                    break;
                case 'Search':
                    addPage = 14
                    break;
                default:
            }
            spuItems = [{
                skuId: this.skuCompo.select.id,
                spuId: this.skuCompo.select.spuId,
                quantity: this.rsOption.quantity,
                customizeType: this.rsInfo.customizeType,
                customizeInfo: []
            }];
            this.log.trace = {
                addPage,
                spuItems,
                settleSource: this.skuCompo.select.spuId,
                traceType: 1
            }
            this.setAddLogAjax()
        },
        setAddLogAjax() { // 加购结算日志
            this.$api.product.setAddBagLog({
                ...this.log.trace
            }).then(response => {})
        },
        // 加购埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        batCate(detail) {
            let batCate = detail?.backCat?.pullPathEn.split('/') || [];
            return batCate?.pop();
        },
        setBuried() {
            const select = this.skuCompo.select || {};
            // tiktok
            this.$fnApi.buried.tiktok({
                type: 'ClickButton',
                params: {
                    content_type: 'product',
                    content_id: this.rsInfo.productCode,
                    currency: 'USD',
                }
            })
            this.$fnApi.buried.tiktok({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    quantity: this.rsOption.quantity,
                    content_id: this.rsInfo.productCode,
                    currency: this.currency.code,
                    price: this.setSymbolReplace(select.displayMarketPrice),
                    value: select.displayMarketPrice
                }
            })
            // gtag 加购事件埋点
            this.$fnApi.buried.gtag({
                type: 'conversion_cart',
                params: {
                    'value': this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                    'currency': 'USD'
                }
            })
            this.$fnApi.buried.gtag({
                type: 'add_to_cart',
                params: {
                    'value': this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                    currency: 'USD',
                    items: [{
                        item_id: select.id,
                        quantity: this.rsOption.quantity||1,
                        group_item_id: select.spuCode,
                        item_name: select.productName,
                        price: this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                        affiliation: 'YFN',
                        item_brand: 'YFN',
                        index: 0,
                        item_variant: select.sellerSku,
                        item_category: this.batCate(this.rsInfo),
                    }]
                }
            })
            // criteo 埋点
            // this.$fnApi.buried.criteo({
            //     type: 'addToCart',
            //     params: [{
            //         id: this.rsInfo.productCode,
            //         price: this.setSymbolReplace(select.displayMarketPrice),
            //         quantity: this.rsOption.quantity,
            //     }]
            // })
            // fbq 埋点
            this.$fnApi.buried.fbq({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    content_ids: this.rsInfo.productCode,
                    currency: 'USD',
                    value: this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                    contents: [{
                        id: this.rsInfo.productCode,
                        quantity: this.rsOption.quantity,
                    }]
                }
            }, this)
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.defaultPopup = {
                position: 'bottom',
                closeable: true,
                overlay: true,
                'get-container': 'body',
                class: 'YfnBag',
                style: {
                    'max-height': '85%'
                }
            }
            this.defaultOptions = {
                quantity: 1,
                hasBtn: true,
                hasImdAddBag: true,
                hasOnlyImdAddBag: true,
                hasAddNoToast: true,
                hasAddSucToast: true,
                hasRequest: true,
                hasCusJump: true,
                hasShowDetail: true,
                btnText: this.$translate('ADD TO BAG'),
                addSucText: this.$translate('Added To Bag Successfully')
            }
            this.skuCompo.list = []
            this.skuCompo.select = {}
        }
    },
}
